
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { TIcon } from '../../shared/general/types/TIcon'
import capitalize from '../../shared/general/services/Formatter'

@Component({
  name: 'BaseIcon',
  components: {
    AlarmClockLight: () => import('../svgs/AlarmClockLight.vue'),
    AlarmClockSolid: () => import('../svgs/AlarmClockSolid.vue'),
    ArrowDownToLineLight: () => import('../svgs/ArrowDownToLineLight.vue'),
    ArrowDownToLineSolid: () => import('../svgs/ArrowDownToLineSolid.vue'),
    ArrowRightLongLight: () => import('../svgs/ArrowRightLongLight.vue'),
    ArrowRightLongSolid: () => import('../svgs/ArrowRightLongSolid.vue'),
    ArrowUpRightFromSquareLight: () => import('../svgs/ArrowUpRightFromSquareLight.vue'),
    ArrowUpRightFromSquareSolid: () => import('../svgs/ArrowUpRightFromSquareSolid.vue'),
    AwardLight: () => import('../svgs/AwardLight.vue'),
    AwardSolid: () => import('../svgs/AwardSolid.vue'),
    BallotCheckLight: () => import('../svgs/BallotCheckLight.vue'),
    BallotCheckSolid: () => import('../svgs/BallotCheckSolid.vue'),
    BarsFilterLight: () => import('../svgs/BarsFilterLight.vue'),
    BarsFilterSolid: () => import('../svgs/BarsFilterSolid.vue'),
    BarsLight: () => import('../svgs/BarsLight.vue'),
    BarsSolid: () => import('../svgs/BarsSolid.vue'),
    BicycleLight: () => import('../svgs/BicycleLight.vue'),
    BicycleSolid: () => import('../svgs/BicycleSolid.vue'),
    BookmarkLight: () => import('../svgs/BookmarkLight.vue'),
    BookmarkSlashLight: () => import('../svgs/BookmarkSlashLight.vue'),
    BookmarkSlashSolid: () => import('../svgs/BookmarkSlashSolid.vue'),
    BookmarkSolid: () => import('../svgs/BookmarkSolid.vue'),
    BriefcaseLight: () => import('../svgs/BriefcaseLight.vue'),
    BriefcaseSolid: () => import('../svgs/BriefcaseSolid.vue'),
    CalendarDaysLight: () => import('../svgs/CalendarDaysLight.vue'),
    CalendarDaysSolid: () => import('../svgs/CalendarDaysSolid.vue'),
    CalendarStarLight: () => import('../svgs/CalendarStarLight.vue'),
    CalendarStarSolid: () => import('../svgs/CalendarStarSolid.vue'),
    CarLight: () => import('../svgs/CarLight.vue'),
    CarSolid: () => import('../svgs/CarSolid.vue'),
    ChalkboardUserLight: () => import('../svgs/ChalkboardUserLight.vue'),
    ChalkboardUserSolid: () => import('../svgs/ChalkboardUserSolid.vue'),
    ChargingStationLight: () => import('../svgs/ChargingStationLight.vue'),
    ChargingStationSolid: () => import('../svgs/ChargingStationSolid.vue'),
    ChartNetworkLight: () => import('../svgs/ChartNetworkLight.vue'),
    ChartNetworkSolid: () => import('../svgs/ChartNetworkSolid.vue'),
    CheckLight: () => import('../svgs/CheckLight.vue'),
    CheckSolid: () => import('../svgs/CheckSolid.vue'),
    ChevronDownLight: () => import('../svgs/ChevronDownLight.vue'),
    ChevronDownSolid: () => import('../svgs/ChevronDownSolid.vue'),
    ChevronLeftLight: () => import('../svgs/ChevronLeftLight.vue'),
    ChevronLeftSolid: () => import('../svgs/ChevronLeftSolid.vue'),
    ChevronRightLight: () => import('../svgs/ChevronRightLight.vue'),
    ChevronRightSolid: () => import('../svgs/ChevronRightSolid.vue'),
    ChevronUpLight: () => import('../svgs/ChevronUpLight.vue'),
    ChevronUpSolid: () => import('../svgs/ChevronUpSolid.vue'),
    CircleInfoLight: () => import('../svgs/CircleInfoLight.vue'),
    CircleInfoSolid: () => import('../svgs/CircleInfoSolid.vue'),
    CircleNotchLight: () => import('../svgs/CircleNotchLight.vue'),
    CircleNotchSolid: () => import('../svgs/CircleNotchSolid.vue'),
    CircleXmarkLight: () => import('../svgs/CircleXmarkLight.vue'),
    CircleXmarkSolid: () => import('../svgs/CircleXmarkSolid.vue'),
    ClipboardListCheckLight: () => import('../svgs/ClipboardListCheckLight.vue'),
    ClipboardListCheckSolid: () => import('../svgs/ClipboardListCheckSolid.vue'),
    ClockLight: () => import('../svgs/ClockLight.vue'),
    ClockSolid: () => import('../svgs/ClockSolid.vue'),
    CoinsLight: () => import('../svgs/CoinsLight.vue'),
    CoinsSolid: () => import('../svgs/CoinsSolid.vue'),
    CrosshairsLight: () => import('../svgs/CrosshairsLight.vue'),
    CrosshairsSolid: () => import('../svgs/CrosshairsSolid.vue'),
    DiagramProjectLight: () => import('../svgs/DiagramProjectLight.vue'),
    DiagramProjectSolid: () => import('../svgs/DiagramProjectSolid.vue'),
    EnvelopeLight: () => import('../svgs/EnvelopeLight.vue'),
    EnvelopeOpenTextLight: () => import('../svgs/EnvelopeOpenTextLight.vue'),
    EnvelopeOpenTextSolid: () => import('../svgs/EnvelopeOpenTextSolid.vue'),
    EnvelopeSolid: () => import('../svgs/EnvelopeSolid.vue'),
    FacebookBrands: () => import('../svgs/FacebookBrands.vue'),
    FaxLight: () => import('../svgs/FaxLight.vue'),
    FaxSolid: () => import('../svgs/FaxSolid.vue'),
    FileArrowDownLight: () => import('../svgs/FileArrowDownLight.vue'),
    FileArrowDownSolid: () => import('../svgs/FileArrowDownSolid.vue'),
    FileCertificateLight: () => import('../svgs/FileCertificateLight.vue'),
    FileCertificateSolid: () => import('../svgs/FileCertificateSolid.vue'),
    FileCheckLight: () => import('../svgs/FileCheckLight.vue'),
    FileCheckSolid: () => import('../svgs/FileCheckSolid.vue'),
    FileLinesLight: () => import('../svgs/FileLinesLight.vue'),
    FileLinesSolid: () => import('../svgs/FileLinesSolid.vue'),
    FileSignatureLight: () => import('../svgs/FileSignatureLight.vue'),
    FileSignatureSolid: () => import('../svgs/FileSignatureSolid.vue'),
    FlaskLight: () => import('../svgs/FlaskLight.vue'),
    FlaskSolid: () => import('../svgs/FlaskSolid.vue'),
    GearsLight: () => import('../svgs/GearsLight.vue'),
    GearsSolid: () => import('../svgs/GearsSolid.vue'),
    GlobeLight: () => import('../svgs/GlobeLight.vue'),
    GlobeSolid: () => import('../svgs/GlobeSolid.vue'),
    HandHoldingBoxLight: () => import('../svgs/HandHoldingBoxLight.vue'),
    HandHoldingBoxSolid: () => import('../svgs/HandHoldingBoxSolid.vue'),
    HandshakeAngleLight: () => import('../svgs/HandshakeAngleLight.vue'),
    HandshakeAngleSolid: () => import('../svgs/HandshakeAngleSolid.vue'),
    HandshakeLight: () => import('../svgs/HandshakeLight.vue'),
    HandshakeSolid: () => import('../svgs/HandshakeSolid.vue'),
    HeadSideBrainLight: () => import('../svgs/HeadSideBrainLight.vue'),
    HeadSideBrainSolid: () => import('../svgs/HeadSideBrainSolid.vue'),
    HeartPulseLight: () => import('../svgs/HeartPulseLight.vue'),
    HeartPulseSolid: () => import('../svgs/HeartPulseSolid.vue'),
    InstagramBrands: () => import('../svgs/InstagramBrands.vue'),
    LightbulbLight: () => import('../svgs/LightbulbLight.vue'),
    LightbulbOnLight: () => import('../svgs/LightbulbOnLight.vue'),
    LightbulbOnSolid: () => import('../svgs/LightbulbOnSolid.vue'),
    LightbulbSolid: () => import('../svgs/LightbulbSolid.vue'),
    LinkedinBrands: () => import('../svgs/LinkedinBrands.vue'),
    LocationCrosshairsLight: () => import('../svgs/LocationCrosshairsLight.vue'),
    LocationCrosshairsSolid: () => import('../svgs/LocationCrosshairsSolid.vue'),
    LocationDotLight: () => import('../svgs/LocationDotLight.vue'),
    LocationDotSolid: () => import('../svgs/LocationDotSolid.vue'),
    MagnifyingGlassLight: () => import('../svgs/MagnifyingGlassLight.vue'),
    MagnifyingGlassPlusLight: () => import('../svgs/MagnifyingGlassPlusLight.vue'),
    MagnifyingGlassPlusSolid: () => import('../svgs/MagnifyingGlassPlusSolid.vue'),
    MagnifyingGlassSolid: () => import('../svgs/MagnifyingGlassSolid.vue'),
    MapLocationDotLight: () => import('../svgs/MapLocationDotLight.vue'),
    MapLocationDotSolid: () => import('../svgs/MapLocationDotSolid.vue'),
    MessageMiddleLight: () => import('../svgs/MessageMiddleLight.vue'),
    MessageMiddleSolid: () => import('../svgs/MessageMiddleSolid.vue'),
    MessagesLight: () => import('../svgs/MessagesLight.vue'),
    MessagesSolid: () => import('../svgs/MessagesSolid.vue'),
    MinusLight: () => import('../svgs/MinusLight.vue'),
    MinusSolid: () => import('../svgs/MinusSolid.vue'),
    NewspaperLight: () => import('../svgs/NewspaperLight.vue'),
    NewspaperSolid: () => import('../svgs/NewspaperSolid.vue'),
    PeopleArrowsLight: () => import('../svgs/PeopleArrowsLight.vue'),
    PeopleArrowsSolid: () => import('../svgs/PeopleArrowsSolid.vue'),
    PeopleCarryBoxLight: () => import('../svgs/PeopleCarryBoxLight.vue'),
    PeopleCarryBoxSolid: () => import('../svgs/PeopleCarryBoxSolid.vue'),
    PhoneFlipLight: () => import('../svgs/PhoneFlipLight.vue'),
    PhoneFlipSolid: () => import('../svgs/PhoneFlipSolid.vue'),
    PhoneLight: () => import('../svgs/PhoneLight.vue'),
    PhoneSolid: () => import('../svgs/PhoneSolid.vue'),
    PlayLight: () => import('../svgs/PlayLight.vue'),
    PlaySolid: () => import('../svgs/PlaySolid.vue'),
    PrintLight: () => import('../svgs/PrintLight.vue'),
    PrintSolid: () => import('../svgs/PrintSolid.vue'),
    ShieldCheckLight: () => import('../svgs/ShieldCheckLight.vue'),
    ShieldCheckSolid: () => import('../svgs/ShieldCheckSolid.vue'),
    SquareEnvelopeLight: () => import('../svgs/SquareEnvelopeLight.vue'),
    SquareEnvelopeSolid: () => import('../svgs/SquareEnvelopeSolid.vue'),
    TagsLight: () => import('../svgs/TagsLight.vue'),
    TagsSolid: () => import('../svgs/TagsSolid.vue'),
    ThumbtackLight: () => import('../svgs/ThumbtackLight.vue'),
    ThumbtackSolid: () => import('../svgs/ThumbtackSolid.vue'),
    TriangleExclamationLight: () => import('../svgs/TriangleExclamationLight.vue'),
    TriangleExclamationSolid: () => import('../svgs/TriangleExclamationSolid.vue'),
    XBrands: () => import('../svgs/XBrands.vue'),
    UmbrellaBeachLight: () => import('../svgs/UmbrellaBeachLight.vue'),
    UmbrellaBeachSolid: () => import('../svgs/UmbrellaBeachSolid.vue'),
    UserDoctorLight: () => import('../svgs/UserDoctorLight.vue'),
    UserDoctorSolid: () => import('../svgs/UserDoctorSolid.vue'),
    UserGroupLight: () => import('../svgs/UserGroupLight.vue'),
    UserGroupSolid: () => import('../svgs/UserGroupSolid.vue'),
    UserHeadsetLight: () => import('../svgs/UserHeadsetLight.vue'),
    UserHeadsetSolid: () => import('../svgs/UserHeadsetSolid.vue'),
    UserHelmetSafetyLight: () => import('../svgs/UserHelmetSafetyLight.vue'),
    UserHelmetSafetySolid: () => import('../svgs/UserHelmetSafetySolid.vue'),
    UtensilsLight: () => import('../svgs/UtensilsLight.vue'),
    UtensilsSolid: () => import('../svgs/UtensilsSolid.vue'),
    WeiboBrands: () => import('../svgs/WeiboBrands.vue'),
    WeixinBrands: () => import('../svgs/WeixinBrands.vue'),
    WhatsappBrands: () => import('../svgs/WhatsappBrands.vue'),
    XingBrands: () => import('../svgs/XingBrands.vue'),
    XmarkLight: () => import('../svgs/XmarkLight.vue'),
    XmarkSolid: () => import('../svgs/XmarkSolid.vue'),
    YelpBrands: () => import('../svgs/YelpBrands.vue'),
    YoutubeBrands: () => import('../svgs/YoutubeBrands.vue'),
  },
})
export default class BaseIcon extends Vue {
  @Prop({ default: 'light' }) type! : TIcon

  @Prop({ required: true }) name! : string

  @Prop({ default: '' }) classes! : string

  @Prop({ default: false }) rotate! : boolean

  private get componentName () : string {
    // transform something like this: name-abc to NameAbc and also add the type like light so the result looks something like this: NameAbcLight
    return `${this.name.replace(/(^\w|-\w)/g, (match) => match.replace('-', '').toUpperCase())}${this.capitalizeFirstLetter(this.type)}`
  }

  private capitalizeFirstLetter (string : string) : string {
    return capitalize(string)
  }
}

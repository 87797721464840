
import { FSXABaseComponent } from 'fsxa-pattern-library'
import { Component, Prop } from 'nuxt-property-decorator'
import BaseGridLayout from '../layouts/BaseGridLayout.vue'
import BaseButton from '../base/BaseButton.vue'
import { globalLabelAsString } from '../../shared/general/services/StoreService'
import { getIndexPageUrl } from '../../middleware/RedirectsHandler'
import BaseHeadline from '../base/BaseHeadline.vue'

@Component({
  name: 'PageNotTranslated',
  components: {
    BaseButton,
    BaseGridLayout,
    BaseHeadline,
  },
})
export default class PageNotTranslated extends FSXABaseComponent {
  @Prop({ default: 'wide' }) size! : 'small' | 'wide'

  homeLink = ''

  async mounted () {
    this.homeLink = await getIndexPageUrl(this.fsxaApi, this.$store.state.Locale.locale)
  }

  private get headline () : string {
    return globalLabelAsString('not_translated_error_label')
  }

  private get homeLabel () : string {
    return globalLabelAsString('go_to_homepage_label')
  }

  private get goBackLabel () : string {
    return globalLabelAsString('back_to_previous_page_label')
  }

  private goBack () : void {
    this.$router.go(-1)
  }
}
